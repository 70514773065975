.subtitle {
    font-size: 28px;
    line-height: var(--small_line-height);
    color: var(--main-color);
}

.subtitle span {
    font-weight: 600;
    line-height: var(--big_line-height);
}

.banner__subtitle {
    margin-bottom: 60px;
}

.what-interesting__subtitle {
    text-transform: capitalize;
    text-align: center;
    margin: 50px 0 60px;
}

.form__subtitle {
    font-size: 18px;
    margin: 15px 0 30px;
}

@media screen and (max-width: 1030px) {
    .subtitle {
        font-size: 22px;
    }

    .banner__subtitle {
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 780px) {
    .subtitle {
        font-size: 16px;
    }

    .what-interesting__subtitle {
        margin: 20px 0 30px;
    }
}

@media screen and (max-width: 650px) {
    .subtitle {
        font-size: 18px;
    }

    .dodo-live__subtitle {
        margin-top: 15px;
    }

    .form__subtitle {
        font-size: 14px;
        margin: 10px 0 25px;
    }
}