.label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    row-gap: 12px;
}

.label_date,
.label_time {
    width: 214px;
    row-gap: 0;
}

.label_phone {

}

.label_date > span,
.label_time > span {
    margin-bottom: 12px;
}

.label input {
    padding: 9px 10px 7px 17px;
    border: 1px solid var(--primary-color);
    border-radius: 10px;
    line-height: var(--big_line-height);
    font-weight: 300;
}

.label input:hover,
.label input:focus {
    border: 1px solid var(--main-color);
}

.label input[type='number']::-webkit-outer-spin-button,
.label input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.label input[type=number] {
    -moz-appearance: textfield;
}

.people-input {
    padding: 9px 0 7px !important;
}


@media screen and (max-width: 650px) {
    .label {
        row-gap: 10px;
    }

    .label_date,
    .label_time {
        row-gap: 0;
    }

    .label_date > span,
    .label_time > span {
        margin-bottom: 10px;
    }

    .label input {
        font-size: 12px;
    }
}