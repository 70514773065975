.media-item {
    width: 100%;
    height: 100%;
    position: relative;
}

.lessons-slider__media-item {
    -webkit-transform: rotate(6deg);
    -ms-transform: rotate(6deg);
    transform: rotate(6deg);
    max-width: 48%;
}

.lessons-slider__media-item .blue-some {
    position: absolute;
    top: -55px;
    right: -45px;
    -webkit-transform: rotate(20deg);
    -ms-transform: rotate(20deg);
    transform: rotate(20deg);
}

.dodo-live__media-item {
    border: 2px dashed var(--primary-color);
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    border-radius: 30px;
    padding: 15px;
}

.dodo-live__media-item img {
    width: 100%;
    --border-top-left-radius: 30px;
    --border-top-right-radius: 30px;
    --border-bottom-right-radius: 30px;
    --border-bottom-left-radius: 30px;
    border-radius: 30px;
}

@media screen and (max-width: 1250px) {
    .lessons-slider__media-item .blue-some {
        width: 80px;
        top: -30px;
        right: -15px;
    }
}

@media screen and (max-width: 1030px) {
    .dodo-live__media-item {
        padding: 10px;
    }

    .lessons-slider__media-item {
        max-width: 50%;
    }
}

@media screen and (max-width: 650px) {
    .lessons-slider__media-item {
        max-width: 85%;
    }
}

@media screen and (max-width: 435px) {
    .dodo-live__media-item {
        padding: 5px;
    }

    .lessons-slider__media-item {
        max-width: 95%;
    }

    .lessons-slider__media-item .blue-some {
        width: 50px;
        top: -25px;
        right: -5px;
    }
}