.react-tel-input {
    font-family: var(--ff-dodo);
    width: 214px;
    border: 1px solid var(--primary-color);
    border-radius: 10px;
}

.react-tel-input .flag-dropdown {
    background-color: var(--secondary-color);
    border: none;
    border-radius: 10px;
}

.react-tel-input .form-control {
    padding-left: 45px;
    letter-spacing: 0;
    width: 212px;
    border: none;
    border-radius: 10px;
    line-height: var(--big_line-height);
    font-weight: 300;
}

.react-tel-input .selected-flag {
    border-radius: 10px;
    width: 25px;
    height: 100%;
    padding-left: 17px;
}

.react-tel-input .form-control:hover,
.react-tel-input .form-control:focus {
    border: none;
}

.react-tel-input:hover,
.react-tel-input:focus {
    border: 1px solid var(--main-color);
}

@media screen and (max-width: 650px) {
    .react-tel-input .form-control {
        font-size: 12px;
    }
}