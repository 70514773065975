.lessons-slider {
    background-color: var(--background-color);
    background-image: url('../../img/pink-snake.png');
    background-repeat: no-repeat;
    background-position: 55% 140%;
}

.lessons-slider__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-column-gap: 2%;
    -moz-column-gap: 2%;
    column-gap: 2%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 150px 0;
}

@media screen and (max-width: 1030px) {
    .lessons-slider {
        background-image: url('../../img/pink-snake_medium.png');
        background-position: 50% 105%;
    }

    .lessons-slider__row {
        padding: 100px 0;
    }
}

@media screen and (max-width: 820px) {
    .lessons-slider__row {
        -webkit-column-gap: 5%;
        -moz-column-gap: 5%;
        column-gap: 5%;
    }
}

@media screen and (max-width: 650px) {
    .lessons-slider {
        background-image: url('../../img/pink-snake_small.png');
        background-position: 3% 105%;
    }

    .lessons-slider__row {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        row-gap: 40px;
        padding: 80px 0;
    }
}