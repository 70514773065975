.bubble-border {
    width: 100%;
    height: 38px;
    background-image: url('../../img/bubbles/bubble.svg');
}

.bubble-border_normal {
    position: absolute;
    top: -36px;
}

.bubble-border_reversed {
    position: absolute;
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
}

@media screen and (max-width: 860px) {
    .bubble-border {
        background-image: url('../../img/bubbles/bubble_medium.svg');
        height: 17px;
    }

    .bubble-border_normal {
        top: -16px;
    }
}

@media screen and (max-width: 430px) {
    .bubble-border {
        background-image: url('../../img/bubbles/bubble_small.svg');
        height: 11px;
    }

    .bubble-border_normal {
        top: -10px;
    }
}