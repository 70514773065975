.qr-code {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: var(--font-size);
    font-weight: 300;
    line-height: var(--big_line-height);
    -webkit-column-gap: 20px;
    -moz-column-gap: 20px;
    column-gap: 20px;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

@media screen and (max-width: 1250px) {
    .qr-code {
        -webkit-column-gap: 20px;
        -moz-column-gap: 20px;
        column-gap: 20px;
        font-size: 20px;
    }
}

@media screen and (max-width: 1160px) {
    .qr-code {
        -webkit-column-gap: 18px;
        -moz-column-gap: 18px;
        column-gap: 18px;
        font-size: 18px;
    }

    .qr-code img {
        width: 86px;
    }
}

@media screen and (max-width: 1030px) {
    .qr-code {
        -webkit-column-gap: 16px;
        -moz-column-gap: 16px;
        column-gap: 16px;
    }
}

@media screen and (max-width: 860px) {
    .qr-code {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .qr-code img {
        width: 64px;
    }
}

@media screen and (max-width: 780px) {
    .qr-code {
        -webkit-column-gap: 10px;
        -moz-column-gap: 10px;
        column-gap: 10px;
        font-size: 14px;
    }
}

@media screen and (max-width: 435px) {
    .qr-code {
        margin-top: 5px;
    }
}