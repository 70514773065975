.title {
    font-size: 120px;
    line-height: var(--small_line-height);
}

.title span {
    color: var(--primary-color);
}

.title_small {
    font-size: 48px;
    font-weight: var(--font-weight);
}

.banner__title {
    margin: 40px 0 20px;
}

.what-interesting__title {
    text-align: center;
    margin-bottom: 105px;
}

.form__title {
    font-size: 34px;
}

@media screen and (max-width: 1200px) {
    .title {
        font-size: 90px;
    }

    .title_small {
        font-size: 42px;
    }
}

@media screen and (max-width: 1030px) {
    .title {
        font-size: 80px;
    }

    .title_small {
        font-size: 36px;
    }

    .what-interesting__title {
        margin-bottom: 70px;
    }
}

@media screen and (max-width: 960px) {
    .title {
        font-size: 60px;
    }

    .title_small {
        font-size: 32px;
    }
}

@media screen and (max-width: 820px) {
    .title_small {
        font-size: 26px;
    }

    .lessons-slider__title {
        font-size: 24px;
    }
}

@media screen and (max-width: 740px) {
    .what-interesting__title {
        margin-bottom: 50px;
    }

    .banner__title {
        margin: 20px 0;
    }
}

@media screen and (max-width: 650px) {
    .title {
        font-size: 54px;
    }

    .title_small {
        font-size: 20px;
    }

    .lessons-slider__title {
        text-align: center;
    }
}

@media screen and (max-width: 435px) {
    .title {
        font-size: 48px;
    }

    .title_small {
        font-size: 18px;
    }
}