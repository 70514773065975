.logo-min {
    width: 70px;
}

@media screen and (max-width: 1030px) {
    .logo {
        width: 200px;
    }
}

@media screen and (max-width: 435px) {
    .logo {
        width: 140px;
    }
}