.what-interesting__steps-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-column-gap: 30px;
    -moz-column-gap: 30px;
    column-gap: 30px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 25px 15px;
    border: 2px solid var(--primary-color);
    border-radius: 20px;
    position: relative;
    height: 370px;
}

.what-interesting__steps-item span {
    font-size: 48px;
    line-height: var(--small_line-height);
    text-align: center;
    z-index: 1;
}

.what-interesting__steps-item_reverse {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}

.step-icon {
    -ms-flex-item-align: start;
    align-self: flex-start;
}

.what-interesting__first-step_image,
.what-interesting__third-step_image {
    position: absolute;
}

.what-interesting__first-step_image {
    top: 20px;
    right: 60px;
}

.what-interesting__second-step_image {
    position: relative;
    margin: 0 auto 20px 0;
}

.what-interesting__third-step_image {
    right: 0;
    margin-right: -2px;
}

@media screen and (max-width: 1250px) {
    .what-interesting__steps-item span {
        font-size: 35px;
    }

    .what-interesting__third-step_image {
        width: 620px;
        margin-top: 88px;
    }
}

@media screen and (max-width: 1030px) {
    .what-interesting__steps-item {
        -webkit-column-gap: 15px;
        -moz-column-gap: 15px;
        column-gap: 15px;
        height: 300px;
    }

    .what-interesting__steps-item span {
        font-size: 32px;
    }

    .step-icon {
        width: 90px;
    }

    .what-interesting__first-step_image {
        width: 40%;
    }

    .what-interesting__second-step_image {
        width: 55%;
    }

    .what-interesting__third-step_image {
        width: 520px;
        right: 0;
        margin-right: -2px;
        margin-top: 65px;
    }
}

@media screen and (max-width: 860px) {
    .what-interesting__steps-item {
        height: 230px;
    }

    .what-interesting__steps-item span {
        font-size: 24px;
    }

    .step-icon {
        width: 70px;
    }

    .what-interesting__first-step_image {
        width: 30%;
    }

    .what-interesting__second-step_image {
        width: 45%;
    }

    .what-interesting__third-step_image {
        width: 390px;
        margin-top: 73px;
    }
}

@media screen and (max-width: 780px) {
    .what-interesting__steps-item {
        padding: 10px;
        height: 180px;
    }

    .step-icon {
        width: 65px;
    }

    .what-interesting__third-step_image {
        width: 320px;
        margin-top: 35px;
    }
}

@media screen and (max-width: 650px) {
    .what-interesting__steps-item {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        height: 300px;
    }

    .what-interesting__steps-item span {
        margin-top: 15px;
    }

    .what-interesting__first-step_image {
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%);
        -ms-transform: translate(-50%);
        transform: translate(-50%);
        width: 50%;
    }

    .what-interesting__second-step_icon {
        margin-left: auto;
    }

    .what-interesting__second-step_image {
        width: 205px;
        margin: auto;
    }

    .what-interesting__third-step_image {
        margin-top: 108px;
    }
}

@media screen and (max-width: 435px) {
    .step-icon {
        width: 55px
    }

    .what-interesting__steps-item span {
        font-size: 20px;
    }

    .what-interesting__third-step_image {
        width: 280px;
        margin-top: 130px;
    }
}

@media screen and (max-width: 385px) {
    .what-interesting__steps-item {
        height: 265px;
    }

    .what-interesting__steps-item span {
        font-size: 16px;
    }

    .what-interesting__third-step_image {
        width: 240px;
        margin-top: 117px;
    }
}

