.wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    overflow-y: scroll;
    padding-right: 0;
}

.wrapper_open-popup {
    overflow-y: hidden;
    padding-right: 17px;
}

.container {
    max-width: 1440px;
    padding: 0 60px;
    margin: auto;
}

.default-text {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: var(--secondary-color);
}

@media screen and (max-width: 1030px) {
    .container {
        padding: 0 40px;
    }
}

@media screen and (max-width: 520px) {
    .container {
        padding: 0 24px;
    }
}