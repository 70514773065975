.datepicker-input {
    background: url('../../img/icons/ic_calendar.svg') no-repeat 95%;
    background-size: 20px;
    width: 100%;
}

.datepicker-input:focus-visible {
    border-bottom-color: var(--primary-color);
    outline: none;
}

.react-datepicker__time-list-item--selected,
.react-datepicker__time-list-item--in-selecting-range,
.react-datepicker__time-list-item--in-range,
.react-datepicker__time-list-item--keyboard-selected {
    background-color: var(--primary-color) !important;
    -webkit-box-shadow: inset 0 0 0 2px white;
    box-shadow: inset 0 0 0 2px white;
    border: var(--primary-color);
}

.react-datepicker__time-list-item--selected:hover,
.react-datepicker__time-list-item--in-selecting-range:hover,
.react-datepicker__time-list-item--in-range:hover,
.react-datepicker__time-list-item--keyboard-selected:hover {
    background-color: var(--primary-color);
}