.what-interesting__steps-line {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    row-gap: 110px;
}

@media screen and (max-width: 1030px) {
    .what-interesting__steps-line {
        row-gap: 80px;
    }
}

@media screen and (max-width: 860px) {
    .what-interesting__steps-line {
        row-gap: 60px;
    }
}

@media screen and (max-width: 780px) {
    .what-interesting__steps-line {
        row-gap: 45px;
    }
}