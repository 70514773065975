.copyright {
    background-color: var(--background-color);
}

.copyright__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 5px 0;
    -webkit-column-gap: 30px;
    -moz-column-gap: 30px;
    column-gap: 30px;
}

.copyright__atr,
.copyright__privacy {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-column-gap: 10px;
    -moz-column-gap: 10px;
    column-gap: 10px;
}

.copyright__atr span {
    font-size: 12px;
}

@media screen and (max-width: 1030px) {
    .copyright__row {
        -webkit-column-gap: 15px;
        -moz-column-gap: 15px;
        column-gap: 15px;
    }
}

@media screen and (max-width: 780px) {
    .copyright__atr span {
        font-size: 10px;
    }
}

@media screen and (max-width: 650px) {
    .copyright__row {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        row-gap: 8px;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .copyright__atr {
        -webkit-column-gap: 5px;
        -moz-column-gap: 5px;
        column-gap: 5px;
    }
}