.what-interesting__little-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 50%;
    flex: 0 1 50%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 36px;
    line-height: var(--big_line-height);
}

.what-interesting__little-info span {
    font-size: 52px;
    line-height: 73px;
    font-weight: var(--font-weight);
    color: var(--primary-color);
    text-transform: uppercase;
}

@media screen and (max-width: 1030px) {
    .what-interesting__little-info {
        font-size: 32px;
    }

    .what-interesting__little-info span {
        font-size: 42px;
    }
}

@media screen and (max-width: 780px) {
    .what-interesting__little-info {
        font-size: 20px;
    }

    .what-interesting__little-info span {
        font-size: 36px;
    }
}