.dodo-slider {
    border: 2px dashed var(--primary-color);
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    border-radius: 30px;
    padding: 15px;
}

.dodo-slider .slick-slider,
.dodo-slider .slick-list {
    border-radius: 35px;
}

.dodo-slider .slick-list .slick-slide img {
    width: 100%;
}

.dodo-slider .arrow_prev,
.dodo-slider .arrow_next {
    position: absolute;
    top: 45%;
    z-index: 1;
    cursor: pointer;
}

.dodo-slider .arrow_prev {
    left: 3%;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.dodo-slider .arrow_next {
    right: 3%;
}

@media screen and (max-width: 1030px) {
    .dodo-slider {
        padding: 10px;
    }
}

@media screen and (max-width: 960px) {
    .dodo-slider .arrow_prev,
    .dodo-slider .arrow_next {
        width: 35px;
    }
}

@media screen and (max-width: 780px) {
    .dodo-slider .arrow_prev,
    .dodo-slider .arrow_next {
        width: 30px;
    }
}

@media screen and (max-width: 630px) {
    .dodo-slider .arrow_prev,
    .dodo-slider .arrow_next {
        width: 25px;
    }
}

@media screen and (max-width: 435px) {
    .dodo-slider {
        padding: 5px;
    }
}