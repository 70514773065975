.footer__geo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    row-gap: 19px;
}

@media screen and (max-width: 1030px) {
    .footer__geo {
        row-gap: 25px;
    }
}

@media screen and (max-width: 435px) {
    .footer__geo {
        row-gap: 15px;
    }
}