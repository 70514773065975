* {
    padding: 0;
    margin: 0;
    border: 0;
}

*, *:before, *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

:focus, :active {
    outline: none;
}

a:focus, a:active {
    outline: none;
}

nav, footer, header, aside {
    display: block;
}

html, body {
    height: 100%;
    width: 100%;
    font-size: 100%;
    line-height: 1;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    font-family: 'Kanit', sans-serif;
}

input, button, textarea {
    font-family: inherit;
}

input::-ms-clear {
    display: none;
}

button {
    cursor: pointer;
}

button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

a, a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

ul li {
    list-style: none;
}

img {
    vertical-align: top;
}

h1, h2, h3, h4, h5, h6 {
    font-size: inherit;
    font-weight: 400;
}

@font-face {
    font-family: 'Dodo Rounded';
    src: url('../src/fonts/DodoRounded-Regular.ttf') format('truetype');
    font-weight: 400;
}

/*@font-face {
    font-family: 'Dodo Rounded';
    src: url('../src/fonts/DodoRounded-Thin.ttf') format('truetype');
    font-weight: 100;
}

@font-face {
    font-family: 'Dodo Rounded';
    src: url('../src/fonts/DodoRounded-ExtraLight.ttf') format('truetype');
    font-weight: 200;
}*/

@font-face {
    font-family: 'Dodo Rounded';
    src: url('../src/fonts/DodoRounded-Light.ttf') format('truetype');
    font-weight: 300;
}

/*
@font-face {
    font-family: 'Dodo Rounded';
    src: url('../src/fonts/DodoRounded-Medium.ttf') format('truetype');
    font-weight: 500;
}
*/

@font-face {
    font-family: 'Dodo Rounded';
    src: url('../src/fonts/DodoRounded-Bold.ttf') format('truetype');
    font-weight: 600;
}

:root {
    --main-color: #000000;
    --primary-color: #FF6900;
    --secondary-color: #FFFFFF;
    --background-color: #FFEFE6;
    --border-radius: 40px;
    --font-size: 24px;
    --font-weight: 600;
    --small_line-height: 116%;
    --big_line-height: 140%;
    --box-shadow: 0px 15px 40px 5px #EDEDED;
    --transition: all ease 0.1s;
    --ff-dodo: 'Dodo Rounded', 'sans-serif';
}

#page {
    height: 100%;
    font-family: var(--ff-dodo);
}