.dodo-live {
    position: relative;
}

.dodo-live__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 110px 0 100px;
}

.dodo-live__info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 55%;
    flex: 0 1 55%;
}

.dodo-live__media {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    margin-top: 160px;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 45%;
    flex: 0 1 45%;
}

.dodo-live__media > span {
    display: block;
    margin-top: 12px;
    padding-left: 10px;
    font-size: var(--font-size);
}

@media screen and (max-width: 1030px) {
    .dodo-live__info,
    .dodo-live__media {
        -webkit-box-flex: 0;
        -ms-flex: 0 1 50%;
        flex: 0 1 50%;
    }

    .dodo-live__media {
        margin-top: 100px;
    }

    .dodo-live__media > span {
        font-size: 20px;
    }
}

@media screen and (max-width: 780px) {
    .dodo-live__row {
        padding: 45px 0 40px;
    }

    .dodo-live__media span {
        font-size: 14px;
    }
}

@media screen and (max-width: 650px) {
    .dodo-live__row {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .dodo-live__media {
        margin-top: 30px;
    }
}
