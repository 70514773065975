.background-form {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 3;
    overflow-y: scroll;
}

.form {
    padding: 35px 70px;
    margin: 2% auto;
    background-color: var(--secondary-color);
    max-width: 890px;
    position: relative;
    z-index: 4;
}

.form ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    row-gap: 20px;
}

.form__confirm-popup {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    -webkit-transition: opacity 0.15s ease-in;
    -o-transition: opacity 0.15s ease-in;
    transition: opacity 0.15s ease-in;
    z-index: -1;
}

.form-popup {
    text-align: center;
    width: 75%;
    padding: 20px 5px;
    margin-bottom: 150px;
    background-color: var(--secondary-color);
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    border-radius: 15px;
    font-size: 20px;
    -webkit-box-shadow: 0 0 15px 3px rgba(0, 0, 0, 0.8);
    box-shadow: 0 0 15px 3px rgba(0, 0, 0, 0.8);
    text-transform: uppercase;
    font-weight: 600;
}

.form__confirm-popup_show {
    opacity: 1;
    z-index: 6;
    -webkit-transition: opacity 0.5s ease-out;
    -o-transition: opacity 0.5s ease-out;
    transition: opacity 0.5s ease-out;
}

@media screen and (max-width: 1030px) {
    .form {
        padding: 30px;
        margin: 0 auto;
    }
}

@media screen and (max-width: 650px) {
    .form-popup {
        font-size: 16px;
    }
}

@media screen and (max-width: 435px) {
    .form-popup {
        width: 90%;
        font-size: 14px;
    }
}