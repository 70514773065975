.radio {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
}

.radio-label {
    font-weight: 300;
    line-height: var(--big_line-height);
    margin-left: 30px;
}

.radio input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
}

.radio-body {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: var(--secondary-color);
    border: 1px solid var(--primary-color);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.radio-body:hover {
    border: 1px solid var(--main-color);
}

.radio input:checked ~ .radio-body {
    background-color: var(--secondary-color);
}

.radio-body:after {
    content: '';
    position: absolute;
    display: none;
}

.radio input:checked ~ .radio-body:after {
    display: block;
}

.radio .radio-body:after {
    width: 12px;
    height: 12px;
    top: 3px;
    left: 3px;
    border-radius: 50%;
    background-color: var(--primary-color);
}

@media screen and (max-width: 650px) {
    .radio-label {
        font-size: 12px;
        line-height: var(--small_line-height);
        margin-left: 25px;
    }

    .radio-body {
        width: 12px;
        height: 12px;
    }

    .radio .radio-body:after {
        width: 6px;
        height: 6px;
        top: 2px;
        left: 2px;
    }
}