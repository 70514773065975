.contact-line {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-column-gap: 13px;
    -moz-column-gap: 13px;
    column-gap: 13px;
    font-size: var(--font-size);
}

.footer__location {
    font-weight: 300;
    line-height: var(--big_line-height);
}

.footer__phone a {
    color: var(--main-color);
    font-size: var(--font-size);
    font-weight: 600;
    line-height: var(--big_line-height);
}

@media screen and (max-width: 1250px) {
    .contact-line,
    .footer__phone a {
        font-size: 20px;
    }
}

@media screen and (max-width: 1160px) {
    .contact-line,
    .footer__phone a {
        font-size: 18px;
    }

    .contact-line img {
        width: 40px;
    }
}

@media screen and (max-width: 780px) {
    .contact-line,
    .footer__phone a {
        font-size: 14px;
    }

    .contact-line img {
        width: 24px;
    }
}