.what-interesting__info-line {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 90px;
    width: 85%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@media screen and (max-width: 1030px) {
    .what-interesting__info-line {
        width: 90%;
        margin-top: 70px;
    }
}

@media screen and (max-width: 780px) {
    .what-interesting__info-line {
        margin-top: 50px;
    }
}

@media screen and (max-width: 520px) {
    .what-interesting__info-line {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        -webkit-flex-direction: column;
        flex-direction: column;
        row-gap: 40px;
    }
}