.form-agreement {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    row-gap: 12px;
}

.form-agreement > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.form-agreement span {
    font-size: 16px;
    font-weight: var(--font-weight);
}

.form-agreement span b {
    color: #FF0000;
}

.form-agreement div > p {
    font-size: 14px;
    font-weight: 300;
    line-height: var(--big_line-height);
    margin-left: 40px;
}

@media screen and (max-width: 780px) {
    .form-agreement div > p {
        font-size: 12px;
    }
}

@media screen and (max-width: 650px) {
    .form-agreement span {
        font-size: 12px;
    }

    .form-agreement div > p {
        margin-left: 35px;
        line-height: var(--small_line-height);
    }
}