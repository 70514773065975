.link {
    color: var(--primary-color);
    font-size: var(--font-size);
}

.link:hover {
    text-decoration: underline;
}

.copy__link {
    font-size: 12px;
}

@media screen and (max-width: 1030px) {
    .link {
        font-size: 20px;
    }

    .copy__link {
        font-size: 12px;
    }
}

@media screen and (max-width: 780px) {
    .link {
        font-size: 16px;
    }

    .copy__link {
        font-size: 10px;
    }
}

@media screen and (max-width: 435px) {
    .copy__link {
        text-align: center;
    }
}