.button {
    background-color: var(--primary-color);
    color: var(--secondary-color);
    padding: 0 64px;
    height: 88px;
    border-radius: var(--border-radius);
    font-size: 32px;
    font-weight: 700;
    text-transform: uppercase;
    position: relative;
    bottom: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-transition: all .15s ease;
    -o-transition: all .15s ease;
    transition: all .15s ease;
    cursor: pointer;
}

.button:hover,
.button_white:hover {
    bottom: 3px;
    -webkit-box-shadow: 0 12px 26px -14px #FF6900FF;
    -moz-box-shadow: 0 12px 26px -14px #FF6900FF;
    box-shadow: 0 12px 26px -14px #FF6900FF;
    -webkit-transition: all .15s ease;
    -o-transition: all .15s ease;
    transition: all .15s ease;
}

.button_white {
    border: 2px solid var(--primary-color);
    color: var(--primary-color);
    background-color: transparent;
    padding: 28px 60px;
}

.what-interesting-step__button {
    position: relative;
    margin: 0 auto;
}

.footer__button {
    -ms-flex-item-align: start;
    align-self: flex-start;
}

.form__button {
    padding: 13px 0;
    width: 100%;
}

@media screen and (max-width: 1160px) {
    .footer__button {
        padding: 0 38px;
    }
}

@media screen and (max-width: 1030px) {
    .button {
        font-size: 28px;
    }
}

@media screen and (max-width: 960px) {
    .banner__button,
    .what-interesting-step__button,
    .what-interesting__button,
    .footer__button,
    .form__button {
        font-size: 16px;
        height: 48px;
    }

    .banner__button,
    .what-interesting-step__button,
    .what-interesting__button {
        padding: 0 36px;
    }

    .footer__button {
        padding: 0 18px;
    }
}

@media screen and (max-width: 650px) {
    .what-interesting-step__button {
        margin: auto;
    }

    .footer__button {
        -ms-flex-item-align: center;
        align-self: center;
        padding: 0 36px;
    }
}

@media screen and (max-width: 435px) {
    .banner__button,
    .what-interesting__button,
    .button_white {
        width: 100%;
        border-radius: 30px;
        text-align: center;
    }

    .what-interesting-step__button {
        border-radius: 30px;
    }
}