.people-counter {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-column-gap: 10px;
    -moz-column-gap: 10px;
    column-gap: 10px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.people-counter span {
    cursor: pointer;
}

.people-input {
    width: 47px;
    text-align: center;
}