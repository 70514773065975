.what-interesting {
    position: relative;
}

.what-interesting__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 140px 0 150px;
}

@media screen and (max-width: 1030px) {
    .what-interesting__row {
        padding: 100px 0 110px;
    }
}

@media screen and (max-width: 780px) {
    .what-interesting__row {
        padding: 50px 0;
    }
}

