.banner {
    background-color: var(--background-color);
    background-image: url('../../img/main-banner.png');
    background-repeat: no-repeat;
    background-position: 90%;
}

.banner__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 60px 0 75px;
}

@media screen and (max-width: 1030px) {
    .banner {
        background-size: cover;
    }
}

@media screen and (max-width: 740px) {
    .banner {
        background-image: url('../../img/main-banner_medium.png');
        background-size: auto;
    }

    .banner__row {
        padding: 30px 0 45px;
    }
}

@media screen and (max-width: 650px) {
    .banner {
        background-image: url("../../img/main-banner_small.png");
        background-position: 100% -35px;
    }
}

@media screen and (max-width: 435px) {
    .banner {
        background-position: 100% 120px;
    }

    .banner__row {
        padding: 30px 0 230px;
    }
}