.checkbox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
}

.checkbox input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
}

.checkbox-body {
    position: absolute;
    height: 20px;
    width: 20px;
    border-radius: 25%;
    background-color: var(--secondary-color);
    border: 1px solid var(--primary-color);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.checkbox-body:hover {
    border: 1px solid var(--main-color);
}

.checkbox input:checked ~ .checkbox-body {
    background-color: var(--primary-color);
}

.checkbox-body:after {
    content: '';
    position: absolute;
    display: none;
}

.checkbox input:checked ~ .checkbox-body:after {
    display: block;
}

.checkbox .checkbox-body:after {
    background-image: url('../../img/icons/ic_check.svg');
    background-repeat: no-repeat;
    width: 12px;
    height: 12px;
    top: 6px;
    left: 4px;
}