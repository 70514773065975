.footer {
    background-color: var(--background-color);
}

.footer__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-column-gap: 45px;
    -moz-column-gap: 45px;
    column-gap: 45px;
    padding: 120px 0;
}

.footer__info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-column-gap: 145px;
    -moz-column-gap: 145px;
    column-gap: 145px;
}

@media screen and (max-width: 1320px) {
    .footer__info {
        -webkit-column-gap: 100px;
        -moz-column-gap: 100px;
        column-gap: 100px;
    }
}

@media screen and (max-width: 1250px) {
    .footer__info {
        -webkit-column-gap: 50px;
        -moz-column-gap: 50px;
        column-gap: 50px;
    }
}

@media screen and (max-width: 1160px) {
    .footer__row {
        -webkit-column-gap: 30px;
        -moz-column-gap: 30px;
        column-gap: 30px;
    }

    .footer__info {
        -webkit-column-gap: 45px;
        -moz-column-gap: 45px;
        column-gap: 45px;
    }
}

@media screen and (max-width: 960px) {
    .footer__row {
        padding: 85px 0;
    }
}

@media screen and (max-width: 860px) {
    .footer__row {
        -webkit-column-gap: 20px;
        -moz-column-gap: 20px;
        column-gap: 20px;
    }

    .footer__info {
        -webkit-column-gap: 30px;
        -moz-column-gap: 30px;
        column-gap: 30px;
    }
}

@media screen and (max-width: 780px) {
    .footer__row {
        padding: 55px 0;
    }
}

@media screen and (max-width: 650px) {
    .footer__row {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-row-gap: 30px;
        row-gap: 30px;
    }
}

@media screen and (max-width: 435px) {
    .footer__row {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }

    .footer__info {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        row-gap: 30px;
    }
}